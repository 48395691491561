import React from 'react'

import { RoutesProps } from '@components/app-routes/routes'
import Typography from '@components/atoms/typography'
import CustomTable from '@components/table'
import { FacilityFilter } from '@interfaces/manage-monitor-filter-facility'
import { Checkbox } from '@material-tailwind/react'

import { get_child_routes } from './helper'

interface RouteFacility extends RoutesProps {
  facility?: FacilityFilter
}

const TableRouting = ({
  routing,
  data,
  onChange,
  isExternal = false,
}: {
  routing: RouteFacility
  onChange: (data: any) => void
  data?: any
  isExternal?: boolean
}) => {
  const table_data = get_child_routes(routing, 0, routing.path)

  return (
    <div className="flex flex-col gap-8">
      <CustomTable
        data={table_data}
        columns={[
          {
            title: 'Menu',
            field: 'title',
            width: 250,
            render: (r: any) => (
              <Typography
                className="text-sm"
                style={{ paddingLeft: r.level * 16 }}
              >
                {r.title}
              </Typography>
            ),
          },
          {
            title: 'Access',
            field: 'access',
            render: (r: any) => {
              const access = isExternal
                ? r.access_external ?? r.access
                : r.access
              return access?.map((a: any) => {
                const access_key = r.path
                return (
                  <div className="mr-4 inline" key={`${access_key}_${a}`}>
                    <Checkbox
                      label={a}
                      labelProps={{ className: 'capitalize' }}
                      value={a}
                      checked={data?.[access_key]?.includes(a) ?? false}
                      onChange={e => {
                        const checked = e.target.checked
                        const access = data?.[access_key]?.split(',') ?? []
                        const next_access = access.filter(
                          (_a: string) => _a !== a
                        )
                        if (checked) {
                          next_access.push(a)
                        }

                        if (next_access.length === 0) {
                          delete data?.[access_key]
                        } else {
                          data[access_key] = next_access.join(',')
                        }
                        onChange(data)
                      }}
                      crossOrigin={undefined}
                    />
                  </div>
                )
              })
            },
          },
        ]}
      />
    </div>
  )
}

export default TableRouting
