import React from 'react'

import {
  FinancialUpload,
  FinancialUploadStatus,
} from '@interfaces/accounting/financials'

const FinancialUploadsStatusLabel = ({
  upload,
}: {
  upload: FinancialUpload
}) => {
  let displayStyle
  let displayName

  switch (upload.state) {
    case FinancialUploadStatus.PROCESSED:
      displayStyle =
        'text-success-main border border-success-main  bg-success-surface'
      displayName = 'Processed'
      break
    case FinancialUploadStatus.INREVIEW:
      displayStyle =
        'text-danger-main border border-danger-main bg-danger-surface'
      displayName = 'In Review'
      break
    case FinancialUploadStatus.PROCESSING:
      displayStyle =
        'text-primary-main border border-primary-main  bg-primary-surface-2'
      displayName = 'Processing'
      break
    case FinancialUploadStatus.VOIDED:
      displayStyle =
        'text-neutral-body-2 border-neutral-border-3 bg-neutral-surface-2'
      displayName = 'Voided'
      break
  }

  return (
    <div className="flex justify-center">
      <span
        className={`py-0.5 px-4 rounded-full capitalize text-xs ${displayStyle}`}
      >
        {displayName}
      </span>
    </div>
  )
}

export default FinancialUploadsStatusLabel
