import React from 'react'

import { capitalizeFirst } from '@amcharts/amcharts5/.internal/core/util/Utils'
import { AccEntityStatus, Entity } from '@interfaces/accounting/financials'

const AccEntityStatusLabel = ({ entity }: { entity: Entity }) => {
  return (
    <div className="flex justify-center">
      <span
        className={`py-0.5 px-4 rounded-full capitalize text-xs ${
          entity.status === AccEntityStatus.ACTIVE
            ? 'text-success-main border border-success-main  bg-success-surface'
            : 'text-neutral-body-2 border-neutral-border-3 bg-neutral-surface-2'
        }`}
      >
        {capitalizeFirst(entity.status)}
      </span>
    </div>
  )
}

export default AccEntityStatusLabel
