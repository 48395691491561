import React, { useState } from 'react'

import { ChevronRightIcon } from '@heroicons/react/24/outline'
import { BankAccount, BankingSource } from '@interfaces/banking/bank-account'
import { Accordion, AccordionHeader } from '@material-tailwind/react'

interface BankListProps {
  banksArray?: BankAccount[]
  can_edit: boolean
}

const Banklist = ({ banksArray }: BankListProps) => {
  const [open, setOpen] = useState<string>('')
  const [accountForUpload] = useState<string>('')

  const handleOpen = (id: string) => {
    setOpen(open === id ? '' : id)
  }

  return (
    <>
      {!(banksArray && banksArray.length) ? (
        <div>No active bank accounts to display.</div>
      ) : (
        <div className="border border border-neutral-border-2 rounded-md ">
          <div className="px-10 text-sm md:text-md grid grid-cols-8 gap-3 text-left font-semibold text-neutral-subtitle-3 py-5 flex items-center border-b border-neutral-border-2">
            <div>Bank</div>
            <div>Acc Number</div>
            <div>Acc Name</div>
            <div>Nickname</div>
            <div>Entity Ownership</div>
            <div>Use</div>
            <div>Source</div>
            <div>Status</div>
          </div>
          {banksArray.map((bank: BankAccount) => (
            <Accordion
              key={bank.id}
              className="relative"
              open={open === bank.id}
              icon={
                <ChevronRightIcon
                  onClick={() => handleOpen(bank.id)}
                  className={`${
                    open === bank.id ? 'rotate-90 stroke-red' : ''
                  } ${
                    bank.source == BankingSource.PLAID && 'hidden'
                  } absolute left-3 h-4 w-4 transition-transform cursor-pointer`}
                />
              }
            >
              <AccordionHeader
                className={
                  open === bank.id || accountForUpload === bank.id
                    ? 'bg-secondary-surface cursor-default'
                    : ' cursor-default'
                }
              >
                <div className="ml-9 text-xs md:text-sm font-light grid grid-cols-8 gap-3 text-left w-full">
                  <div>{bank.institutionName}</div>
                  <div>
                    {bank.accountNumber ? bank.accountNumber : bank.mask}
                  </div>
                  <div>
                    {bank.officialName ? bank.officialName : bank.nickname}
                  </div>
                  <div>{bank.nickname}</div>
                  <div>{bank.entityOwnership}</div>
                  <div className="capitalize">{bank.utilization}</div>
                  <div className="capitalize">{bank.source}</div>
                  <div className="capitalize">{bank.status}</div>
                </div>
              </AccordionHeader>
            </Accordion>
          ))}
        </div>
      )}
    </>
  )
}
export default Banklist
