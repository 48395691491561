import { useCallback, useEffect, useRef, useState } from 'react'
import { noop } from 'lodash'
import { usePlaidLink } from 'react-plaid-link'
import { useMutation } from 'react-query'

import { Utilization } from '@interfaces/banking/bank-account'
import BankService from '@services/api-admin/data-source-banks'

export const usePlaid = () => {
  const [linkToken, setLinkToken] = useState<string | null>(null)
  const [config, setConfig] = useState<Parameters<typeof usePlaidLink>[0]>({
    token: '',
    onSuccess: () => noop(),
  })
  const [isLoading, setIsLoading] = useState<boolean | undefined>(undefined)
  const [passedFormData, setPassedFormData] = useState<{
    entityOwnership: string
    accountUse: Utilization
    emails: string[]
  }>({ entityOwnership: '', accountUse: Utilization.OTHER, emails: [] })
  const { open, ready } = usePlaidLink(config)
  const slugRef = useRef<string>('')
  const accessTokenRef = useRef<string | undefined>()

  useEffect(() => {
    if (linkToken) {
      setConfig({
        token: linkToken,
        onSuccess,
        onExit,
      })
    }
  }, [linkToken])

  useEffect(() => {
    if (config.token && ready) {
      open()
    }
  }, [config, ready])

  const { mutate: getLinkToken } = useMutation(
    (params: { accessToken?: string; slugName: string }) =>
      BankService.getPlaidLinkToken(params.slugName, params.accessToken),
    {
      onSuccess: token => {
        setLinkToken(token)
      },
    }
  )

  const { mutate: setPlaidAccessToken } = useMutation(
    (publicToken: string) => {
      return BankService.setPlaidAccessToken(slugRef.current, {
        accountUse: passedFormData.accountUse,
        entityOwnership: passedFormData.entityOwnership,
        publicToken: publicToken,
        emails: passedFormData.emails,
      })
    },
    {
      onSuccess: () => setIsLoading(false),
      onError: () => setIsLoading(false),
    }
  )

  const { mutate: updatePlaidAccessToken } = useMutation(
    () => {
      return BankService.updatePlaidAccessToken(
        slugRef.current,
        accessTokenRef.current || ''
      )
    },
    {
      onSuccess: () => setIsLoading(false),
      onError: () => setIsLoading(false),
    }
  )

  const onSuccess = (publicToken: string) => {
    if (accessTokenRef.current) {
      updatePlaidAccessToken()
    } else {
      setPlaidAccessToken(publicToken)
    }
  }

  const onExit = useCallback(() => setIsLoading(false), [])

  const initializePlaid = ({
    slugName,
    formData,
    accessToken,
  }: {
    slugName: string
    formData?: {
      entityOwnership: string
      accountUse: Utilization
      emails: string[]
    }
    accessToken?: string
  }) => {
    setIsLoading(true)
    slugRef.current = slugName
    accessTokenRef.current = accessToken
    formData && setPassedFormData(formData)
    if (!linkToken) {
      getLinkToken({ accessToken, slugName })
    } else {
      open()
    }
  }

  return { initializePlaid, isLoading }
}
