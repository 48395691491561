import React, { useRef } from 'react'

import BaseLayout from '@components/layouts/base-layout'

import EntitiesOverview from './entities'
import TemplatesOverview from './templates'

const EntitiesTemplatesOverviewL3 = () => {
  const templatesRef = useRef<any>(null)

  const handleTemplatesRefresh = () => {
    if (templatesRef.current) {
      templatesRef.current.refetchTemplates()
    }
  }

  return (
    <BaseLayout title="Accounting Config">
      <EntitiesOverview onTriggerRefetch={handleTemplatesRefresh} />
      <TemplatesOverview ref={templatesRef} />
    </BaseLayout>
  )
}
export default EntitiesTemplatesOverviewL3
