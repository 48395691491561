import React from 'react'

import Typography from '@components/atoms/typography'
import Table from '@components/table'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { Dialog, DialogBody, DialogHeader } from '@material-tailwind/react'

interface ErrorsPopupProps {
  open: boolean
  columns: any
  data: any[]
  handler: () => void
}

const ErrorsPopup = ({
  open = false,
  handler,
  data,
  columns,
}: ErrorsPopupProps) => {
  return (
    <Dialog open={open} handler={() => handler()} size="lg">
      <DialogHeader className="border-b border-neutral-border-2 flex justify-between items-center">
        <Typography className="text-xl text-neutral-black font-medium">
          Errors
        </Typography>
        <button onClick={() => handler()}>
          <XMarkIcon className="w-6 h-6" />
        </button>
      </DialogHeader>
      <DialogBody className="flex flex-col text-sm text-black max-h-[80vh] overflow-y-auto">
        <Table
          containerClass="max-h-[60vh] overflow-auto [&>table>thead]:sticky [&>table>thead]:top-0 [&>table>thead>tr>th]:uppercase"
          loading={false}
          columns={columns}
          data={data}
        />
      </DialogBody>
    </Dialog>
  )
}

export default ErrorsPopup
