import { useContext } from 'react'

import AuthContext from '@contexts/auth'
import { Filter } from '@interfaces/analytics-risk-filter-type-key'

export const get_child_routes = (c: any, level: number, path: string) => {
  const current_item = {
    title: c.title,
    level,
    path,
    access: c.access,
    access_external: c.access_external,
  }
  if (!c.routes) {
    return [current_item]
  } else {
    const child = c.routes.reduce(
      (_p: any[], _c: any) => [
        ..._p,
        ...get_child_routes(_c, level + 1, `${path}_${_c.path}`),
      ],
      []
    )
    return [current_item, ...child]
  }
}

export const get_routes_counter = (
  c: any,
  level: number,
  path: string,
  is_external: boolean,
  checked_access: any
) => {
  const data = get_child_routes(c, level, path)

  const { total, checked } = data?.reduce(
    ({ total: _total, checked: _checked }, c) => {
      const access = is_external ? c.access_external ?? c.access : c.access
      const access_key = c.path

      return {
        total: _total + (access ?? []).length,
        checked:
          _checked +
          (checked_access?.[access_key]
            ? checked_access?.[access_key].split(',').length
            : 0),
      }
    },
    { total: 0, checked: 0 }
  )

  return { total, checked, data }
}

export const get_category_types_counter = () => {
  const { optionFilters } = useContext(AuthContext)
  const { categoryTypeOptions = [] } = optionFilters

  const options = categoryTypeOptions.filter(
    (x: Filter) => x.type_key.toLowerCase() !== 'all'
  )
  const total = options.reduce((p: number, c: Filter) => {
    return p + c.type_val.length
  }, 0)

  return { options, total }
}
