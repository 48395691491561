import React from 'react'

import FilterTypeKey from '@components/filters/filter-type-key'
import Table from '@components/table'
import { Checkbox } from '@material-tailwind/react'

import { get_category_types_counter } from './helper'

const TableCategory = ({
  data,
  onChange,
}: {
  onChange: (data: any) => void
  data?: any
}) => {
  const { options } = get_category_types_counter()
  return (
    <>
      <div className="hidden">
        <FilterTypeKey use_filter_exclusion={false} />
      </div>
      <Table
        data={options}
        columns={[
          { field: 'type_key', title: 'Category' },
          {
            field: 'type_val',
            title: 'Options',
            render: (r: any) => {
              return (
                <div className="grid grid-cols-4 gap-4">
                  {r.type_val?.map((tv: any) => {
                    return (
                      <div className="inline" key={tv.id}>
                        <Checkbox
                          id={tv.id}
                          label={tv.type}
                          labelProps={{ className: 'capitalize' }}
                          value={tv.id?.toString()}
                          checked={!data?.split(',').includes(`${tv.id}`)}
                          onChange={e => {
                            const is_checked = e.target.checked
                            const next_category_types = (
                              data?.split(',') ?? []
                            ).filter(
                              (_ct: string) =>
                                _ct && _ct.toString() !== tv.id.toString()
                            )
                            if (!is_checked) {
                              next_category_types.push(tv.id)
                            }

                            onChange(next_category_types.join(','))
                          }}
                          crossOrigin={undefined}
                        />
                      </div>
                    )
                  })}
                </div>
              )
            },
          },
        ]}
      />
    </>
  )
}

export default TableCategory
