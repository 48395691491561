import React, { useState } from 'react'
import { AxiosError } from 'axios'
import { useForm } from 'react-hook-form'
import { useMutation } from 'react-query'

import Button from '@components/atoms/button'
import FilesUploadDropZone from '@components/file-upload-modal/drop-zone'
import { useToastNotification } from '@helpers/notification-hook'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { Entity } from '@interfaces/accounting/financials'
import {
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
} from '@material-tailwind/react'
import { MAX_FILE_SIZE } from '@pages/administration/setting/accounting-config/constants'
import { MAX_FILE_SIZE_TEXT } from '@pages/manage/monitor/actions/document-centre/constants'
import { AccountingService } from '@services/api-admin/data-source-accounting'

interface AccountingUploaderProps {
  open: boolean
  entity?: Entity
  handleOpen: () => void
}

const UploaderDialog = ({
  open,
  entity,
  handleOpen,
}: AccountingUploaderProps) => {
  const {
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm()
  const [uploads, setUploads] = useState<any>([])
  const { displayToastError, displayToastSuccess } = useToastNotification()

  const handleSuccess = () => {
    displayToastSuccess('Upload Successful')
    handleOpen()
  }
  const handleError = (err: AxiosError) => {
    const data = err.response?.data as object
    let errorMsg = null
    if (data && 'error' in data) {
      errorMsg = data.error as string
    }
    if (errorMsg) {
      displayToastError(undefined, errorMsg)
    } else {
      displayToastError(err.response?.status)
    }
  }

  const { mutate: upload, isLoading: isUploading } = useMutation(
    () => {
      const params = {
        legalEntityID: entity?.ID ?? '',
        slugName: entity?.slugName ?? '',
        file: uploads.financials[0],
      }

      return AccountingService.uploadFile(params)
    },
    {
      onSuccess: () => handleSuccess(),
      onError: (err: AxiosError) => handleError(err),
    }
  )

  const onSubmit = async (data: any) => {
    upload(data)
  }
  const isNextDisabled =
    Object.keys(errors).length > 0 ||
    !Object.keys(uploads).includes('financials') ||
    isUploading

  const cancelSelection = (itemKey: any) => {
    const updatedUploads = { ...uploads }
    delete updatedUploads[itemKey]
    setUploads(updatedUploads)
  }
  return (
    <>
      <Dialog
        open={open}
        handler={handleOpen}
        className="flex flex-col h-[70%]"
        size="md"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogHeader>
            <div className="flex justify-between w-full">
              <span>Upload Accounting Data for {entity?.entityNickname}</span>
              <XMarkIcon
                onClick={handleOpen}
                className="w-8 h-8 cursor-pointer hover:opacity-50"
              />
            </div>
          </DialogHeader>
          <DialogBody className="mx-5">
            <div className="w-full">
              <div className="flex flex-col">
                <div className="font-bold">Upload File</div>
                <div className="">
                  <FilesUploadDropZone
                    id={''}
                    containerClass="relative border-dashed border-2 border-neutral-border-1 rounded-lg cursor-pointer bg-[#f5f5f5] h-40 mt-3 px-2"
                    fileTypeText={'.pdf'}
                    isLoading={false}
                    maxSize={MAX_FILE_SIZE}
                    maxFiles={1}
                    titleMaxFilesSize={MAX_FILE_SIZE_TEXT}
                    fileTypes={{
                      'application/xlsx': ['.xlsx'],
                    }}
                    handleCancel={() => cancelSelection('financials')}
                    uploader={(file: Array<any>) => {
                      setValue('file', file)
                      setUploads({
                        ...uploads,
                        ['financials']: file,
                      })
                    }}
                    isHintHidden={true}
                  />
                </div>
                <span className="text-xs font-medium  opacity-50 text-center mt-5 ">
                  1 file maximum. Only XLSX files allowed. Max size file 5MB.
                </span>
              </div>
            </div>
          </DialogBody>
          <DialogFooter className="flex flex-row justify-center px-10 absolute bottom-0 w-full">
            <Button
              color="primary"
              className="mx-2 px-10"
              disabled={isNextDisabled}
              type="submit"
            >
              Upload
            </Button>
          </DialogFooter>
        </form>
      </Dialog>
    </>
  )
}
export default UploaderDialog
