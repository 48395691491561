import React, { useContext, useEffect, useState } from 'react'
import { AxiosError } from 'axios'
import { useQuery } from 'react-query'

import Button from '@components/atoms/button'
import Typography from '@components/atoms/typography'
import DynamicMenu from '@components/dynamic-menu'
import Pagination from '@components/pagination'
import CustomTable from '@components/table'
import AuthContext from '@contexts/auth'
import {
  ArrowPathIcon,
  PencilIcon,
  PlusIcon,
} from '@heroicons/react/24/outline'
import { Entity } from '@interfaces/accounting/financials'
import { Alert, Card, CardBody, Tooltip } from '@material-tailwind/react'
import { EntityService } from '@services/api-admin/settings-entities'

import AddEditEntityDialog from '../dialogs/add-entity-dialog'

import AccEntityStatusLabel from './status-label'

interface EntitiesOverviewProps {
  onTriggerRefetch: any
}
const EntitiesOverview = ({ onTriggerRefetch }: EntitiesOverviewProps) => {
  const { company } = useContext(AuthContext)
  const [isEntityDialogOpen, setIsEntityDialogOpen] = useState<boolean>(false)

  const [selectedEntityInfo, setSelectedEntityInfo] = useState<any>()
  const PER_PAGE = 5
  const [page, setPage] = useState<number>(0)
  const [perPage, setPerPage] = useState<number>(PER_PAGE)

  const filters = {
    slugName: company?.slug_name ?? '',
    page: page + 1,
    size: perPage,
  }

  const {
    data: entityData,
    isLoading,
    error,
    refetch,
  } = useQuery(['allEntities', filters], () =>
    EntityService.listEntities(filters)
  )

  const handleOpenEntityDialog = () => {
    isEntityDialogOpen && setSelectedEntityInfo(undefined)

    setIsEntityDialogOpen(!isEntityDialogOpen)
  }

  const columns = [
    {
      title: 'Legal Name',
      align: 'start',
      field: 'entityLegalName',
      className: 'border-none',
    },
    {
      title: 'Nickname',
      align: 'start',
      field: 'entityNickname',
      className: 'border-none',
    },

    {
      title: 'Currency',
      align: 'start',
      field: 'currency',
      className: 'border-none',
    },
    {
      title: 'Geography',
      align: 'start',
      field: 'geographies',
      className: 'border-none',
    },
    {
      title: 'Status',
      align: 'center',
      field: 'status',
      className: 'border-none',
      render: (entity: Entity) => {
        return <AccEntityStatusLabel entity={entity} />
      },
    },
    {
      title: '',
      align: 'center',
      field: '',
      className: 'border-none',

      render: (entity: any) => {
        const entityMenuItems = [
          {
            icon: <PencilIcon className="w-4" />,
            label: <div className="ml-2"> Edit </div>,
            provider: () => {
              setSelectedEntityInfo(entity)
              handleOpenEntityDialog()
            },
          },
        ]

        return <DynamicMenu menuItems={entityMenuItems} />
      },
    },
  ]

  useEffect(() => {
    setPage(0)
  }, [perPage])

  useEffect(() => {
    onTriggerRefetch()
  }, [entityData])

  return (
    <>
      <Card>
        <CardBody>
          <div className="flex justify-between mb-4">
            <div>
              <Typography variant="h6">Entities</Typography>
            </div>

            <div className="flex items-center gap-2">
              <Tooltip content={<div>Refresh</div>} placement="top">
                <div>
                  <Button
                    className="mx-2"
                    onClick={() => {
                      refetch()
                    }}
                  >
                    <ArrowPathIcon className={'w-4'} />
                  </Button>
                </div>
              </Tooltip>
              <Button
                onClick={handleOpenEntityDialog}
                className="rounded-md"
                color="primary"
              >
                <span className="bold text-lg mr-1">
                  <PlusIcon className="mr-2 w-4 h-4" />
                </span>
                Add New Entity
              </Button>
            </div>
          </div>

          {isLoading ? (
            <div className="w-full flex justify-center mt-2">
              {' '}
              <ArrowPathIcon className="w-7 animate-spin" />
            </div>
          ) : error ? (
            <div>
              <Alert className="border border-danger-main text-danger-main text-center mb-6">
                {((error as AxiosError).response?.data as any)?.error ??
                  (error as AxiosError).message}
              </Alert>
            </div>
          ) : !!entityData.body.length ? (
            <>
              <CustomTable
                data={entityData.body}
                columns={columns as any}
                borderless={true}
              />
              {entityData?.page.total > PER_PAGE && (
                <Pagination
                  id="accounting-entities-table-pagination"
                  total={entityData?.page.total ?? 0}
                  page={page}
                  onChangePage={p => setPage(p)}
                  limit={perPage}
                  limits={Array(2)
                    .fill('')
                    .map((_, i) => PER_PAGE * (i + 1))}
                  onChangeLimit={l => {
                    setPage(1)
                    setPerPage(l)
                  }}
                />
              )}
            </>
          ) : (
            <div>
              <div className="w-full mt-6 mb-1">
                No Active Entities to display.
              </div>
            </div>
          )}
        </CardBody>
      </Card>

      <AddEditEntityDialog
        open={isEntityDialogOpen}
        handleOpen={handleOpenEntityDialog}
        selectedEntityInfo={selectedEntityInfo}
        refetch={refetch}
      />
    </>
  )
}
export default EntitiesOverview
