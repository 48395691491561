import React, { useContext, useEffect, useState } from 'react'
import moment from 'moment'

import FilterFinancialUploadStatus from '@components/filters/filter-accounting-uploads-status'
import FilterDateRange from '@components/filters/filter-date-range'
import FilterEntity from '@components/filters/filter-entity'
import L3TabsLayout from '@components/layouts/l3-tabs-layout'
import AuthContext from '@contexts/auth'
import { EntityInfo } from '@interfaces/financial'

import AccoutingUploadsL5 from './accounting'

const tabs = [{ label: 'Accounting', component: <AccoutingUploadsL5 /> }]

const UploadsOverviewL3 = () => {
  const {
    activeFilters,
    appliedFilters,
    company,
    setAppliedFilters,
    optionFilters,
  } = useContext(AuthContext)
  const { entities = [], financialEntities } = optionFilters
  const { financialEntityID } = appliedFilters
  const activeEntity = financialEntities?.find(
    (entity: EntityInfo) => entity.entity_id == financialEntityID
  )
  /**
   * observe whats filter required at initiation
   */
  const [filterReady, setFilterReady] = useState<boolean>(false)

  useEffect(() => {
    if (
      !filterReady &&
      activeFilters.financialEntityID &&
      activeFilters.entityStartDate &&
      activeFilters.entityEndDate &&
      activeFilters.financialEntityCurrency
    ) {
      setAppliedFilters(activeFilters)
      setFilterReady(true)
    }
  }, [activeFilters])

  useEffect(() => {
    setFilterReady(false)
  }, [company?.slug_name])

  const start = appliedFilters?.entityStartDate
    ? moment.utc(appliedFilters?.entityStartDate).format('yyyy-MM-DD')
    : undefined
  const end = appliedFilters?.entityEndDate
    ? moment.utc(appliedFilters?.entityEndDate).format('yyyy-MM-DD')
    : undefined
  /**
   * observe reset button disable state criteria
   */
  const disableReset =
    entities?.[0]?.ID === financialEntityID &&
    (!appliedFilters.financialUploadStatus ||
      appliedFilters.financialUploadStatus === 'All') &&
    activeEntity?.start_date === start &&
    activeEntity?.end_date === end

  return (
    <L3TabsLayout
      title="Uploads"
      filters={{
        left: <FilterDateRange type={'financials'} />,
        middle: <FilterFinancialUploadStatus />,
        right: <FilterEntity />,
      }}
      tabs={tabs}
      disableReset={disableReset}
    />
  )
}
export default UploadsOverviewL3
